import React from 'react';

import Head from '../components/Head';
import SubpageHeader from '../components/SubpageHeader';
import Footer from '../components/Footer';

import GlobalStyle from '../styles/global';

interface SubpageLayoutData {
  pagetitle?: string;
}

const SubpageLayout: React.FC<SubpageLayoutData> = ({
  pagetitle,
  children,
}) => {
  return (
    <>
      <Head pageTitle={pagetitle} />
      <GlobalStyle />
      <SubpageHeader />
      {children}
      <Footer />
    </>
  );
};

export default SubpageLayout;
