import styled from 'styled-components';
import font from '../theme/variables/font';

interface SubpageData {
  background: string;
  secondaryImage: string;
}

export const Container = styled.div<SubpageData>`
  .subpage {
    &__hero {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 780px) {
        flex-direction: column;
      }

      .hero {
        &__left-side {
          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 50%;
          padding: 0 80px;

          @media screen and (max-width: 950px) {
            padding: 0 40px;
          }

          @media screen and (max-width: 780px) {
            height: 60vh;
            width: 100%;
            padding: 100px 40px 30px;
          }

          @media screen and (max-width: 650px) {
            padding: 100px 20px 30px;
          }

          @media screen and (max-width: 540px) and (max-height: 740px) {
            height: 70vh;
          }
        }

        &__right-side {
          background-image: url(${({ background }) => background});
          background-size: cover;
          background-position: center;
          height: 100vh;
          width: 50%;

          @media screen and (max-width: 780px) {
            height: 40vh;
            width: 100%;
          }

          @media screen and (max-width: 540px) and (max-height: 740px) {
            height: 30vh;
          }
        }

        &__logo {
          img {
            height: 80px;
            max-width: 220px;
            margin-bottom: 40px;

            @media screen and (max-width: 1380px) {
              height: 60px;
            }

            @media screen and (max-width: 780px) {
              height: 44px;
              margin-bottom: 20px;
            }
          }
        }

        &__title {
          max-width: 650px;
          margin-bottom: 30px;

          color: #343648;
          font-size: 5rem;
          font-weight: bold;
          line-height: 1;

          span {
            color: #ffb56c;
          }

          @media screen and (max-width: 1640px) {
            max-width: 600px;

            font-size: 4rem;
          }

          @media screen and (max-width: 1380px) {
            font-size: 3.5rem;
          }

          @media screen and (max-width: 1250px) {
            font-size: 3rem;
          }

          @media screen and (max-width: 780px) {
            max-width: 300px;
            color: #645b5b;
            margin-bottom: 30px;
          }

          @media screen and (max-width: 380px) and (max-height: 740px) {
            font-size: 2rem;
          }
        }

        &__subtitle {
          max-width: 620px;
          margin-bottom: 40px;

          font-size: 1.5rem;
          line-height: 1.3;

          @media screen and (max-width: 1640px) {
            max-width: 500px;

            font-size: 1.2rem;
          }

          @media screen and (max-width: 1380px) {
            max-width: 450px;

            font-size: 1rem;
          }

          @media screen and (max-width: 780px) {
            display: none;
          }
        }

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;

          background-color: #ffb56c;
          width: 460px;
          padding: 32px 0;
          border-radius: 8px;
          border: 0;
          outline: 0;

          color: #343648;
          font-family: ${font.family.poppins};
          font-size: 2rem;
          font-weight: bold;
          line-height: 1;
          text-decoration: none;

          cursor: pointer;

          &:hover {
            background-color: #ffc68e;
          }

          @media screen and (max-width: 1380px) {
            width: 100%;
          }

          @media screen and (max-width: 780px) {
            font-size: 1.5rem;
          }
        }
      }
    }

    &__content {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 780px) {
        flex-direction: column;
        margin-top: 40px;
      }

      .content__section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background: #ffffff;
        width: 50%;
        padding: 80px;

        &--secondary {
          background: #f2f2f2;
        }

        @media screen and (max-width: 950px) {
          padding: 40px;
        }

        @media screen and (max-width: 780px) {
          width: 100%;
          padding: 40px 20px;
        }

        .section {
          &__title {
            max-width: 280px;

            color: #645b5b;
            font-size: 3.75rem;
            line-height: 1;
            margin-bottom: 20px;

            span {
              color: #ffb56c;
            }

            @media screen and (max-width: 1640px) {
              font-size: 3.5rem;
            }

            @media screen and (max-width: 1380px) {
              font-size: 3rem;
            }

            @media screen and (max-width: 1250px) {
              font-size: 2.5rem;
            }

            @media screen and (max-width: 380px) and (max-height: 740px) {
              font-size: 2rem;
            }
          }

          &__text {
            max-width: 690px;

            color: #645b5b;
            font-size: 1.5rem;
            line-height: 1.3;
            text-align: justify;

            @media screen and (max-width: 1640px) {
              font-size: 1.4rem;
            }

            @media screen and (max-width: 1380px) {
              font-size: 1.2rem;
            }

            @media screen and (max-width: 1250px) {
              font-size: 1rem;
            }

            @media screen and (max-width: 380px) and (max-height: 740px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;
