import React, { useState } from 'react';

import { Link as GatsbyLink } from 'gatsby';

import { HeaderContainer } from '../../styles/header';

import { Link } from './styles';

import Logo from '../../assets/img/logo.svg';

const SubpageHeader: React.FC = () => {
  const [stateMenu, setStateMenu] = useState(false);

  function closeResponsiveMenu() {
    setStateMenu(false);
  }

  return (
    <HeaderContainer navbarStatus menuStatus={stateMenu}>
      <div className="header__holder">
        <GatsbyLink to="/">
          <img className="header__logo" src={Logo} alt="Logotipo Petbrands" />
        </GatsbyLink>
        <nav>
          <Link
            activeClassName="active"
            to="/extrapet/"
            onClick={() => closeResponsiveMenu()}
          >
            Extrapet
          </Link>
          <Link
            activeClassName="active"
            to="/hospet/"
            onClick={() => closeResponsiveMenu()}
          >
            Hospet
          </Link>
          <Link
            activeClassName="active"
            to="/performancepet/"
            onClick={() => closeResponsiveMenu()}
          >
            Performancepet
          </Link>
          <Link
            activeClassName="active"
            to="/popularpet/"
            onClick={() => closeResponsiveMenu()}
          >
            PopularPet
          </Link>
        </nav>
        <div className="header__hidden" />
        <button
          type="button"
          className="header__menu-bars"
          onClick={() => setStateMenu(!stateMenu)}
        >
          <div className="menu-bars__bar menu-bars__bar--top" />
          <div className="menu-bars__bar menu-bars__bar--center" />
          <div className="menu-bars__bar menu-bars__bar--bottom" />
        </button>
      </div>
    </HeaderContainer>
  );
};

export default SubpageHeader;
