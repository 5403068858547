import React from 'react';

import { Container } from '../../styles/subpageStyles';
import SubpageLayout from '../../theme/SubpageLayout';

import extrapetImage from '../../assets/img/empresas/extrapet/background.jpg';
import extrapetLogo from '../../assets/img/empresas/extrapet/logo.svg';

const ExtrapetPage: React.FC = () => {
  return (
    <SubpageLayout pagetitle="Extrapet">
      <Container background={extrapetImage} secondaryImage={extrapetImage}>
        <div className="subpage__hero">
          <div className="hero__left-side">
            <div className="hero__logo">
              <img src={extrapetLogo} alt="Extrapet logotipo" />
            </div>
            <h1 className="hero__title">
              Alguma frase sobre o <span>produto</span> em questão
            </h1>
            <div className="hero__subtitle">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
            </div>
            <a
              className="hero__button"
              type="button"
              href="http://www.extrapet.com.br/"
            >
              Acessar o site
            </a>
          </div>
          <div className="hero__right-side" />
        </div>
        <div className="subpage__content">
          <div className="content__section">
            <h2 className="section__title">
              Sobre a <span>empresa</span>
            </h2>
            <div className="section__text">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam
            </div>
          </div>
          <div className="content__section content__section--secondary">
            <h2 className="section__title">
              Sobre a <span>mercado</span>
            </h2>
            <div className="section__text">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam
            </div>
          </div>
        </div>
      </Container>
    </SubpageLayout>
  );
};

export default ExtrapetPage;
